import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getBookerName, getPrettyDateTime } from '../../../../utility'
import { Rating } from '../../../../types/Rating'
import styled from 'styled-components'
import Prop from './Prop'
import Toggle from '../../../UI/NewToggle/NewToggle'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { useState } from 'react'

type Props = {
  data: Rating
}

const Wrapper = styled.div`
  width: calc(100% / 2 - 15px / 2);
`

const RatingItem: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toggleRatingVisibility = functional.use(Routes.TOGGLE_RATING_VISIBILITY)
  const [isVisible, setIsVisible] = useState<boolean>(data.isVisible)

  const toggle = async () => {
    const status = await toggleRatingVisibility({ ratingUUID: data.uuid })
    if (!!status) {
      setIsVisible(!isVisible)
    }
  }

  return (
    <Wrapper className="border border-blue-thirdary p-3">
      <div className="font-bold">{getBookerName(data.booker)}</div>
      <div className="text-xs border-b border-gray-primary">{getPrettyDateTime(new Date(data.createdOn))}</div>
      <div className="font-bold text-sm mt-2">{t.admin.users.detail.ratings.public}</div>
      <Prop label={t.admin.users.detail.ratings.quality} value={data.quality} />
      <Prop label={t.admin.users.detail.ratings.reliability} value={data.reliability} />
      <Prop label={t.admin.users.detail.ratings.communication} value={data.communication} />
      <Prop label={t.admin.users.detail.ratings.cooperation} value={data.cooperation} />
      <div className="text-sm">{data.text}</div>
      <div className="font-bold text-sm mt-2">{t.admin.users.detail.ratings.private}</div>
      <Prop label={t.admin.users.detail.ratings.quality} value={data.internal.quality} />
      <Prop label={t.admin.users.detail.ratings.reliability} value={data.internal.reliability} />
      <Prop label={t.admin.users.detail.ratings.communication} value={data.internal.communication} />
      <Prop label={t.admin.users.detail.ratings.cooperation} value={data.internal.cooperation} />
      <div className="text-sm">{data.internal.text}</div>
      <div className="border-t border-gray-primary mt-2 pt-2 flex justify-between">
        <div className="text-sm font-bold">{t.admin.users.detail.ratings.visibility}</div>
        <Toggle checked={isVisible} onChange={toggle} />
      </div>
    </Wrapper>
  )
}

export default RatingItem
