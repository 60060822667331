import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Invoice } from '../../../types/Invoice'
import { ArrowDownOnSquareIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { formatEUR, getPrettyDate } from '../../../utility'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'

type Props = {
  data: Invoice
}

const InvoiceItem: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getName = () => {
    return data.project.nameAndLocation.name
  }

  const getTotal = () => {
    return formatEUR(data.payment.totalInCents / 100)
  }

  return (
    <div className="flex items-center">
      <div className="w-6/12">{getName()}</div>
      <div className="w-3/12 flex justify-end">{getTotal()}</div>
      <div className="w-3/12 flex gap-5 justify-end">
        <a className="tooltip" data-tip={t.admin.invoices.download} href={data.pdfURL} target="_blank" rel="noreferrer">
          <ArrowDownOnSquareIcon className="text-blue-primary h-5" />
        </a>
        {data.payment.completed && (
          <div className="tooltip" data-tip={t.admin.invoices.payed}>
            <CheckBadgeIcon className="text-blue-primary h-5" />
          </div>
        )}
        {!data.payment.completed && (
          <div className="tooltip" data-tip={t.admin.invoices.notPayed}>
            <ExclamationCircleIcon className="text-red h-5" />
          </div>
        )}
        {getPrettyDate(new Date(data.createdOn))}
      </div>
    </div>
  )
}

export default InvoiceItem
