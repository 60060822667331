import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import MediumContainer from '../../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { useEffect, useState } from 'react'
import { Project, VerifiedBy } from '../../../../types/Project'
import { Invoice } from '../../../../types/Invoice'
import { Artist } from '../../../../types/Artist'
import { Booker } from '../../../../types/Booker'
import { useParams } from 'react-router-dom'
import Loading from '../../../UI/Loading/Loading'
import Milestones, { View } from '../../../UI/Milestones/Milestones'
import InvoiceItem from '../../Invoices/InvoiceItem'
import BookerProps from '../../Users/Detail/BookerProps'
import ArtistProps from '../../Users/Detail/ArtistProps'

type ProjectInsight = {
  project: Project
  invoices: Invoice[]
  booker?: Booker
  artist?: Artist
}

const Detail = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getProjectInsight = functional.use(Routes.GET_PROJECT_INSIGHT)
  const [projectInsight, setProjectInsight] = useState<ProjectInsight>(null)
  const { uuid } = useParams()

  useEffect(() => {
    const get = async () => {
      const projectInsight = await getProjectInsight({ uuid })
      console.log(projectInsight)

      setProjectInsight(projectInsight)
    }
    if (!projectInsight) {
      get()
    }
  }, [projectInsight, getProjectInsight, uuid])

  const isExternalProject = () => {
    return projectInsight.project.verification.verifiedBy === VerifiedBy.EXTERNAL
  }

  if (!projectInsight) {
    return (
      <div>
        <Loading loading={false} />
      </div>
    )
  }
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">{projectInsight.project.nameAndLocation.name}</div>
        <div className="flex gap-10">
          <div className="flex flex-col mt-10 flex-1">
            <div className="font-bold mb-2">{t.admin.projects.detail.booker}</div>
            {isExternalProject() ? (
              <div className="text-sm">{t.admin.projects.detail.noBookerIsExternalProject}</div>
            ) : (
              <BookerProps booker={projectInsight.booker} />
            )}
          </div>
          {!!projectInsight.artist && (
            <div className="flex flex-col mt-10 flex-1">
              <div className="font-bold mb-2">{t.admin.projects.detail.artist}</div>
              <ArtistProps artist={projectInsight.artist} />
            </div>
          )}
        </div>
        {!isExternalProject() && (
          <>
            <div className="flex flex-col mt-10 gap-2">
              <div className="font-bold">{t.admin.projects.detail.invoices}</div>
              {projectInsight.invoices.map((i) => (
                <InvoiceItem data={i} key={i.uuid} />
              ))}
            </div>
            <div className="flex flex-col mt-10 gap-2">
              <div className="font-bold">{t.admin.projects.detail.milestones}</div>
              <Milestones project={projectInsight.project} view={View.FULL} readOnly />
            </div>
          </>
        )}
      </div>
    </MediumContainer>
  )
}

export default Detail
