import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import MediumContainer from '../../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../../UI/Loading/Loading'
import InvoiceItem from '../../Invoices/InvoiceItem'
import ArtistProps from './ArtistProps'
import { UserInsight } from '../types'
import ProjectItem from '../../Projects/List/ProjectItem'
import RatingItem from './RatingItem'
import Button from '../../../UI/NewButton/NewButton'
import toast, { TYPE } from '../../../../toast'

const ArtistDetail = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getUserInsight = functional.use(Routes.GET_USER_INSIGHT)
  const [userInsight, setUserInsight] = useState<UserInsight>(null)
  const { uuid } = useParams()
  const toggleArtistVisility = functional.use(Routes.TOGGLE_ARTIST_VISIBILITY)

  useEffect(() => {
    const get = async () => {
      const userInsight = await getUserInsight({ artistUUID: uuid })
      setUserInsight(userInsight)
    }
    if (!userInsight) {
      get()
    }
  }, [userInsight, getUserInsight, uuid])

  const toggleVisibility = async () => {
    const status = await toggleArtistVisility({ uuid })
    if (!!status) {
      const updatedUserInsight = { ...userInsight, artist: { ...userInsight.artist, isVisible: !userInsight.artist.isVisible } }
      setUserInsight(updatedUserInsight)
    } else {
      toast(TYPE.ERROR, t.admin.users.detail.artistVisibility.error)
    }
  }

  if (!userInsight) {
    return (
      <div>
        <Loading loading={false} />
      </div>
    )
  }
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">
          {t.admin.users.detail.artistPrefix}
          {userInsight.artist.personalDetail.username}
        </div>
        <div className="border border-blue-primary p-3 mt-10">
          <div className="font-bold">{t.admin.users.detail.artistVisibility.title}</div>
          <div className="my-3">
            {userInsight.artist.isVisible ? t.admin.users.detail.artistVisibility.textIsActive : t.admin.users.detail.artistVisibility.textIsInactive}
          </div>
          <Button
            text={userInsight.artist.isVisible ? t.admin.users.detail.artistVisibility.toggleOff : t.admin.users.detail.artistVisibility.toggleOn}
            onClick={toggleVisibility}
            className="btn-secondary w-fit !text-sm"
          />
        </div>
        <div className="flex gap-10">
          <div className="flex flex-col mt-10 flex-1">
            <div className="font-bold mb-2">{t.admin.users.detail.properties}</div>
            <ArtistProps artist={userInsight.artist} />
          </div>
        </div>
        <div className="flex flex-col mt-10 gap-2">
          <div className="font-bold">{t.admin.users.detail.projects}</div>
          {userInsight.projects.map((p) => (
            <ProjectItem data={p} key={p.uuid} />
          ))}
        </div>
        <div className="flex flex-col mt-10 gap-2">
          <div className="font-bold">{t.admin.users.detail.invoices}</div>
          {userInsight.invoices.map((i) => (
            <InvoiceItem data={i} key={i.uuid} />
          ))}
        </div>
        <div className="flex flex-col mt-10 gap-2">
          <div className="font-bold">{t.admin.users.detail.ratings.title}</div>
          <div className="flex flex-wrap gap-[15px]">
            {userInsight.ratings.map((r) => (
              <RatingItem data={r} key={r.uuid} />
            ))}
          </div>
        </div>
      </div>
    </MediumContainer>
  )
}

export default ArtistDetail
