import { Artist } from '../../../../types/Artist'
import styled from 'styled-components'
import Icon from '../../ProfileIcon/Icon'
import Favorite from '../../../Artist/Favorite/Favorite'
import { ArtistListItem } from '../../../../types/Request'
import { getArtistName } from '../../../../utility'

const BackgroundImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
`

type Props = { artist: Artist | ArtistListItem; initFavorite?: boolean; hideName?: boolean }

const SmallCard: React.FC<Props> = ({ artist, initFavorite, hideName = false }) => {
  const link = `/artist/view/${artist.personalDetail.username}`
  return (
    <div>
      <div className="h-20 w-full relative">
        <a href={link}>
          <BackgroundImage src={artist.profileImages.backgroundImageURL} />
        </a>
        <div className="rounded-full h-full absolute left-0 top-0">
          <a href={link}>
            <Icon artist={artist as Artist} className="!h-full border border-white" image={artist?.profileImages?.personImageURL} />
          </a>
          {typeof initFavorite === 'boolean' && <Favorite className="left-0 bottom-0" artistUUID={artist.uuid} init={initFavorite} />}
        </div>
      </div>
      {!hideName && (
        <a href={link} className="text-center truncate text-sm mt-1 block">
          {getArtistName(artist as Artist)}
        </a>
      )}
    </div>
  )
}

export default SmallCard
