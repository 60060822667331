import { useSelector } from 'react-redux'
import { Booker } from '../../../../types/Booker'
import Prop from './Prop'
import props from '../../../../redux/props'

type Props = {
  booker: Booker
}

const BookerProps: React.FC<Props> = ({ booker }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!booker) return null
  return (
    <div>
      <Prop label={t.generic.mail} value={booker.accountDetail.mail} />
      <Prop label={t.generic.firstname} value={booker.billingDetail.firstname} />
      <Prop label={t.generic.lastname} value={booker.billingDetail.lastname} />
      <Prop label={t.generic.companyName} value={booker.billingDetail.companyName} />
      <Prop label={t.generic.street} value={booker.billingDetail.street} />
      <Prop label={t.generic.postcode} value={booker.billingDetail.postcode} />
      <Prop label={t.generic.city} value={booker.billingDetail.city} />
    </div>
  )
}

export default BookerProps
