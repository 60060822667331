import { MilestoneView } from '../types'
import Milestone from '../Milestone/Milestone'

const MinifiedList: MilestoneView = ({ project, milestones, isArtist, readOnly = false }) => {
  return (
    <div>
      {milestones.map((m) => (
        <Milestone key={m.uuid} project={project} milestone={m} milestones={milestones} isArtist={isArtist} minified readOnly={readOnly} />
      ))}
    </div>
  )
}

export default MinifiedList
