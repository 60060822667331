import Form from '../UI/Form/Form'
import Layout from '../UI/Layout/Layout'
import NewInput from '../UI/NewInput/NewInput'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import { Level, PurposeFlag } from '../../types/Auth'
import NewButton from '../UI/NewButton/NewButton'
import LostPassword from './LostPassword/RequestReset'
import SectionHeader from '../UI/Pipeline/SectionHeader'
import SmallContainer from '../UI/Container/SmallContainer'

const Login = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()

  const submit = async () => {
    const token = await auth.login(mail, password)
    if (token) {
      cookies.set('token', token, { path: '/' })

      const accountLink = await auth.resolveToken(token)

      if (accountLink) {
        if (accountLink.level === Level.DEFAULT) {
          if (accountLink.purposeFlag === PurposeFlag.BOOKER) {
            navigate('/home/booker')
          } else {
            navigate('/home/artist')
          }
        } else if (accountLink.level === Level.ADMIN) {
          navigate('/admin')
        }
      }
    } else {
      toast(TYPE.ERROR, t.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false}>
      <SmallContainer className="pt-5">
        <SectionHeader headline={t.login.headline} description={t.login.description} />
        <div className="mt-7 max-w-xs mx-auto">
          <Form onSubmit={submit}>
            <div className="flex gap-2 flex-col">
              <NewInput placeholder={t.generic.mail} onChange={setMail} value={mail} required={true} />
              <NewInput placeholder={t.generic.password} onChange={setPassword} value={password} required={true} type="password" />
              <NewButton type="submit" text={t.login.cta} className="w-full btn-secondary mt-5" />
              <NewButton onClick={() => navigate('/register')} text={t.login.registerCTA} className="w-full btn-secondary" />
            </div>
          </Form>
          <LostPassword />
        </div>
      </SmallContainer>
    </Layout>
  )
}

export default Login
