import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Tags from './Tags/Tags'
import Dashboard from './Dashboard/Dashboard'
import { Level } from '../../types/Auth'
import Projects from './Projects/Projects'
import Invoices from './Invoices/Invoices'
import Users from './Users/Users'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'users/*'} element={<Users />} />
        <Route path={'invoices'} element={<Invoices />} />
        <Route path={'projects/*'} element={<Projects />} />
        <Route path={'tags'} element={<Tags />} />
        <Route path="*" element={<Navigate replace to="dashboard" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
