import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import MediumContainer from '../../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../../UI/Loading/Loading'
import { UserInsight } from '../types'
import BookerProps from './BookerProps'
import InvoiceItem from '../../Invoices/InvoiceItem'
import ProjectItem from '../../Projects/List/ProjectItem'

const BookerDetail = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getUserInsight = functional.use(Routes.GET_USER_INSIGHT)
  const [userInsight, setUserInsight] = useState<UserInsight>(null)
  const { uuid } = useParams()

  useEffect(() => {
    const get = async () => {
      const userInsight = await getUserInsight({ bookerUUID: uuid })
      console.log(userInsight)

      setUserInsight(userInsight)
    }
    if (!userInsight) {
      get()
    }
  }, [userInsight, getUserInsight, uuid])

  if (!userInsight) {
    return (
      <div>
        <Loading loading={false} />
      </div>
    )
  }
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">
          {t.admin.users.detail.bookerPrefix}
          {userInsight.booker.billingDetail.companyName ||
            `${userInsight.booker.billingDetail.firstname} ${userInsight.booker.billingDetail.lastname}`}
        </div>
        <div className="flex gap-10">
          <div className="flex flex-col mt-10 flex-1">
            <div className="font-bold mb-2">{t.admin.users.detail.properties}</div>
            <BookerProps booker={userInsight.booker} />
          </div>
        </div>
        <div className="flex flex-col mt-10 gap-2">
          <div className="font-bold">{t.admin.users.detail.projects}</div>
          {userInsight.projects.map((p) => (
            <ProjectItem data={p} key={p.uuid} />
          ))}
        </div>
        <div className="flex flex-col mt-10 gap-2">
          <div className="font-bold">{t.admin.users.detail.invoices}</div>
          {userInsight.invoices.map((i) => (
            <InvoiceItem data={i} key={i.uuid} />
          ))}
        </div>
      </div>
    </MediumContainer>
  )
}

export default BookerDetail
