import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { useEffect, useState } from 'react'
import Input from '../../UI/NewInput/NewInput'
import Dropdown from '../../UI/Dropdown/Dropdown'
import { Invoice, InvoiceType } from '../../../types/Invoice'
import InvoiceItem from './InvoiceItem'
import { formatEUR } from '../../../utility'

enum AllType {
  ALL = 'ALL',
}

type CustomInvoiceType = InvoiceType | AllType

const Invoices = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const listInvoices = functional.use(Routes.LIST_INVOICES)
  const [invoices, setInvoices] = useState<Invoice[]>(null)
  const [search, setSearch] = useState('')
  const [typeSelection, setTypeSelection] = useState<CustomInvoiceType>(null)

  useEffect(() => {
    const pull = async () => {
      const invoices = await listInvoices({})
      setInvoices(invoices)
    }
    if (!invoices) {
      pull()
    }
  }, [invoices, listInvoices])

  const filter = (invoice: Invoice) => {
    if (!!search) {
      const searchStatus =
        invoice.project.nameAndLocation.name.toLowerCase().includes(search.toLowerCase()) ||
        formatEUR(invoice.payment.totalInCents / 100).includes(search.toLowerCase())
      return searchStatus && (typeSelection === null || typeSelection === AllType.ALL || typeSelection === invoice.type)
    }
    return typeSelection === null || typeSelection === AllType.ALL || typeSelection === invoice.type
  }

  if (!invoices) return null
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">{t.admin.invoices.title}</div>
        <div className="mt-3">
          <div className="flex gap-3">
            <Input value={search} onChange={setSearch} placeholder={t.admin.invoices.search} />
            <Dropdown
              className="!w-1/3"
              showEmptySelection={false}
              onChange={setTypeSelection}
              value={typeSelection}
              items={[AllType.ALL, ...Object.values(InvoiceType)].map((c) => ({ value: c, label: t.admin.invoices.typeSelection[c] }))}
            />
          </div>
          <div className="flex flex-col mt-5 gap-2">
            {invoices.filter(filter).map((a) => (
              <InvoiceItem data={a} key={a.uuid} />
            ))}
          </div>
        </div>
      </div>
    </MediumContainer>
  )
}

export default Invoices
