import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import MediumContainer from '../../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { useEffect, useState } from 'react'
import Input from '../../../UI/NewInput/NewInput'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { Project, Status } from '../../../../types/Project'
import ProjectItem from './ProjectItem'

enum AllStatus {
  ALL = 'ALL',
}

type CompletedStatus = Status | AllStatus

const Projects = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const listProjects = functional.use(Routes.LIST_PROJECTS_ADMIN)
  const [projects, setProjects] = useState<Project[]>(null)
  const [search, setSearch] = useState('')
  const [statusSelection, setStatusSelection] = useState<CompletedStatus>(AllStatus.ALL)

  useEffect(() => {
    const pull = async () => {
      const projects = await listProjects({})
      setProjects(projects)
    }
    if (!projects) {
      pull()
    }
  }, [projects, listProjects])

  const filter = (project: Project) => {
    if (!!search) {
      const searchStatus = project.nameAndLocation.name.toLowerCase().includes(search.toLowerCase())
      return searchStatus && (statusSelection === null || statusSelection === AllStatus.ALL || statusSelection === project.status)
    }
    return statusSelection === null || statusSelection === AllStatus.ALL || statusSelection === project.status
  }

  if (!projects) return null
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">{t.admin.projects.title}</div>
        <div className="mt-3">
          <div className="flex gap-3">
            <Input value={search} onChange={setSearch} placeholder={t.admin.projects.search} />
            <Dropdown
              className="!w-1/3"
              showEmptySelection={false}
              onChange={setStatusSelection}
              value={statusSelection}
              items={[AllStatus.ALL, ...Object.values(Status)].map((c) => ({ value: c, label: t.admin.projects.statusSelection[c] }))}
            />
          </div>
          <div className="flex flex-col mt-5 gap-2">
            {projects.filter(filter).map((a) => (
              <ProjectItem data={a} key={a.uuid} />
            ))}
          </div>
        </div>
      </div>
    </MediumContainer>
  )
}

export default Projects
