import { Milestone } from '../../../../../types/Milestone'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { AddOnStatus, Project, Status } from '../../../../../types/Project'
import { formatPrice } from '../../../../../utility'
import GetIcon from '../../../GetIcon/GetIcon'

type Props = {
  project: Project
  milestone: Milestone
  minified?: boolean
  isArtist: boolean
  readOnly?: boolean
}

const Price: React.FC<Props> = ({ project, milestone, minified, isArtist, readOnly = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const addOn = milestone.payload.addOn

  const isCompleted = project.status === Status.COMPLETED || milestone.payload.addOn.status === AddOnStatus.COMPLETED
  const isPaymentPending = !isCompleted && milestone.payload.addOn.status === AddOnStatus.PENDING_PAYMENT
  const isAcceptancePending = milestone.payload.addOn.status === AddOnStatus.PENDING

  const Prop: React.FC<{ name: string; value: number }> = ({ name, value }) => (
    <div className={`flex flex-col gap-1  ${minified ? 'md:gap-0' : 'md:flex-row md:gap-2'} items-center justify-center md:mb-1`}>
      <div className={` text-sm font-bold ${isCompleted ? 'text-white' : 'text-gray-primary'}`}>{name}</div>
      <div className={` text-sm  ${isCompleted ? 'text-white' : 'text-blue-primary'}`}>{formatPrice(value)}</div>
      {isPaymentPending && !isArtist && !readOnly && (
        <a href={'/home/booker/finance'} className={`bold text-sm font-thin  ${isCompleted ? 'text-magenta-primary' : 'text-magenta-primary'}`}>
          {`( ${t.milestone.addOnDecision.hint.isPaymentPending.booker} )`}
        </a>
      )}
      {isPaymentPending && isArtist && !readOnly && (
        <div className={`flex gap-3 flex-row items-center text-sm font-thin  ${isCompleted ? 'text-magenta-primary' : 'text-magenta-primary'}`}>
          {`( ${t.milestone.addOnDecision.hint.isPaymentPending.artist} )`}
          {!minified && (
            <a href={`/home/artist/chat/${project.uuid}`} className="flex gap-2 flex-row items-center text-sm font-thin text-magenta-primary">
              {t.milestone.addOnDecision.hint.isPaymentPending.artistOpenChat}
              <GetIcon name="chat" className="fill-magenta-primary h-4" />
            </a>
          )}
        </div>
      )}
      {isAcceptancePending && !readOnly && (
        <div className={` text-sm font-thin  ${isCompleted ? 'text-magenta-primary' : 'text-magenta-primary'}`}>
          {`( ${isArtist ? t.milestone.addOnDecision.hint.isAcceptancePending.artist : t.milestone.addOnDecision.hint.isAcceptancePending.booker} )`}
        </div>
      )}
    </div>
  )

  return (
    <div className="w-full flex flex-col items-center justify-center">
      {addOn.additionalAttentees && <Prop name={t.priceCalculator.addOn.additionalAttentees} value={addOn.price} />}
      {addOn.additionalOtherCosts && <Prop name={t.priceCalculator.addOn.additionalOtherCosts} value={addOn.price} />}
      {!minified && addOn.additionalOtherCostsDescription && (
        <div className={`text-xs md:text-sm w-full text-center ${isCompleted ? 'text-white' : 'text-gray-primary'}`}>
          {addOn.additionalOtherCostsDescription}
        </div>
      )}
      {addOn.additionalSquareMeter && (
        <Prop
          name={`${t.priceCalculator.addOn.additionalSquareMeter} (${addOn.additionalSquareMeter}${t.generic.squareMeter})`}
          value={addOn.price}
        />
      )}
      {addOn.conceptAdded && <Prop name={t.priceCalculator.addOn.concept} value={addOn.price} />}
      {addOn.insuranceAdded && <Prop name={t.priceCalculator.addOn.insurance} value={addOn.price} />}
      {addOn.primerAdded && <Prop name={t.priceCalculator.addOn.primer} value={addOn.price} />}
    </div>
  )
}

export default Price
