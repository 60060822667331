import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { ExclamationCircleIcon, NoSymbolIcon, PlayIcon } from '@heroicons/react/24/outline'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'
import { Project, Status } from '../../../../types/Project'

type Props = {
  data: Project
}

const ProjectItem: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getName = () => {
    return data.nameAndLocation.name
  }

  return (
    <div className="flex items-center">
      <a className="block w-10/12 hover:underline" href={`/admin/projects/${data.uuid}`}>
        {getName()}
      </a>
      <div className="w-2/12 flex gap-5 justify-end">
        <div className="tooltip" data-tip={t.admin.projects.statusSelection[data.status]}>
          {data.status === Status.PENDING_DOWNPAYMENT && <ExclamationCircleIcon className="text-red h-5" />}
          {data.status === Status.IN_PROGRESS && <PlayIcon className="text-blue h-5" />}
          {data.status === Status.COMPLETED && <CheckBadgeIcon className="text-blue h-5" />}
          {data.status === Status.CANCELLED && <NoSymbolIcon className="text-red h-5" />}
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
