import { Navigate, Route, Routes } from 'react-router-dom'
import List from './List/List'
import Detail from './Detail/Detail'

const Projects = () => {
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
      <Route path={'/:uuid'} element={<Detail />} />
      {/* <Route path="*" element={<Navigate replace to="/admin/projects" />} /> */}
    </Routes>
  )
}

export default Projects
