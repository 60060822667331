import { useSelector } from 'react-redux'
import { Milestone, MilestoneType } from '../../../../types/Milestone'
import { Project } from '../../../../types/Project'
import GetIcon from '../../GetIcon/GetIcon'
import props from '../../../../redux/props'

type Props = {
  project: Project
  milestone: Milestone
  minified?: boolean
  isArtist: boolean
  readOnly?: boolean
}

const CompleteFinalPaymentHint: React.FC<Props> = ({ project, milestone, minified, isArtist, readOnly = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isPaymentPending = milestone.acceptedByArtist && milestone.acceptedByBooker && !milestone.isCompleted
  const isAcceptancePending = milestone.acceptedByArtist && !milestone.acceptedByBooker

  const isVisible = milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT && (isPaymentPending || isAcceptancePending)

  if (!isVisible) return <></>
  return (
    <div className="mb-1">
      {isPaymentPending && (
        <div
          className={`flex gap-3 flex-row items-center justify-center text-sm font-thin  ${
            milestone.isCompleted ? 'text-magenta-primary' : 'text-magenta-primary'
          }`}
        >
          {isArtist && `( ${t.milestone.completeFinalPayment.hint.isPaymentPending.artist} )`}
          {!minified && !readOnly && isArtist && (
            <a href={`/home/artist/chat/${project.uuid}`} className="flex gap-2 flex-row items-center text-sm font-thin text-magenta-primary">
              {t.milestone.completeFinalPayment.hint.isPaymentPending.artistOpenChat}
              <GetIcon name="chat" className="fill-magenta-primary h-4" />
            </a>
          )}
          {!minified && !readOnly && !isArtist && (
            <a href={'/home/booker/finance'} className={`bold text-sm font-thin  text-magenta-primary`}>
              {`( ${t.milestone.completeFinalPayment.hint.isPaymentPending.booker} )`}
            </a>
          )}
        </div>
      )}
      {isAcceptancePending && !readOnly && (
        <div className={` text-sm font-thin text-center  text-magenta-primary`}>
          {`( ${
            isArtist
              ? t.milestone.completeFinalPayment.hint.isAcceptancePending.artist
              : t.milestone.completeFinalPayment.hint.isAcceptancePending.booker
          } )`}
        </div>
      )}
    </div>
  )
}

export default CompleteFinalPaymentHint
