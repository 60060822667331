import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import Upsert from './Tag/Upsert'
import toast, { TYPE } from '../../../toast'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import NewButton from '../../UI/NewButton/NewButton'
import Tag from './Tag/Tag'
import { Tag as TagType } from '../../../types/Tag'
import MediumContainer from '../../UI/Container/MediumContainer'

const Tags = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tagFeature = crud.use(props.TAG)
  const [tags, setTags] = useState<TagType[]>(null)

  useEffect(() => {
    const listTags = async () => {
      const tags: TagType[] = await tagFeature.list({})
      if (Array.isArray(tags)) {
        setTags(tags)
      } else {
        toast(TYPE.ERROR, t.admin.tags.list.error)
      }
    }
    if (!tags) {
      listTags()
    }
  }, [tags, tagFeature, t])

  const mergeList = (tag: TagType) => {
    if (tags.find((t) => t.uuid === tag.uuid)) {
      setTags(tags.map((t) => (t.uuid === tag.uuid ? tag : t)))
    } else {
      setTags([...tags, tag])
    }
  }

  return (
    <MediumContainer>
      <div className="py-10">
        <div className="flex justify-between items-center">
          <div className="text-lg font-bold">{t.admin.tags.headline}</div>
          <Upsert callback={mergeList}>
            <NewButton text={t.admin.tags.upsert.cta}>
              <PlusCircleIcon className="w-5 h-5" />
            </NewButton>
          </Upsert>
        </div>
        <div className="flex flex-col gap-1 mt-3">{tags && tags.map((t) => <Tag callback={mergeList} tag={t} key={t.uuid} />)}</div>
      </div>
    </MediumContainer>
  )
}

export default Tags
