import { useSelector } from 'react-redux'
import Prop from './Prop'
import props from '../../../../redux/props'
import { Artist } from '../../../../types/Artist'

type Props = {
  artist: Artist
}

const ArtistProps: React.FC<Props> = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!artist) return null
  return (
    <div>
      <Prop label={t.generic.businessType.label} value={t.generic.businessType[artist.billingDetail.businessType]} />
      <Prop label={t.generic.mail} value={artist.accountDetail.mail} />
      <Prop label={t.generic.firstname} value={artist.billingDetail.firstname} />
      <Prop label={t.generic.lastname} value={artist.billingDetail.lastname} />
      <Prop label={t.generic.companyName} value={artist.billingDetail.companyName} />
      <Prop label={t.generic.street} value={artist.billingDetail.street} />
      <Prop label={t.generic.postcode} value={artist.billingDetail.postcode} />
      <Prop label={t.generic.city} value={artist.billingDetail.city} />
      <Prop label={t.generic.invoice.taxID} value={artist.invoiceDetail.taxID} />
      <Prop label={t.generic.invoice.taxNumber} value={artist.invoiceDetail.taxNumber} />{' '}
      <Prop label={t.generic.invoice.smallBusinessRegulation.hint} value={artist.invoiceDetail.smallBusinessRegulationActive ? 'Ja' : 'Nein'} />
      <Prop label={t.generic.invoice.stripeAccountId} value={artist.invoiceDetail.stripe.accountID} />
      <Prop label={t.generic.invoice.stripePersonId} value={artist.invoiceDetail.stripe.personID} />
    </div>
  )
}

export default ArtistProps
