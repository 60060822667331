import { Navigate, Route, Routes } from 'react-router-dom'
import List from './List/List'
import ArtistDetail from './Detail/Artist'
import BookerDetail from './Detail/Booker'

const Users = () => {
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
      <Route path={'/artist/:uuid'} element={<ArtistDetail />} />
      <Route path={'/booker/:uuid'} element={<BookerDetail />} />
      <Route path="*" element={<Navigate replace to="/admin/users" />} />
    </Routes>
  )
}

export default Users
