import { Invoice } from './Invoice'
import { AddOn, Project } from './Project'

export enum PaymentType {
  BOOKER_DOWN_PAYMENT = 'BOOKER_DOWN_PAYMENT',
  // the full payment includes the down payment and the final payment
  BOOKER_FULL_PAYMENT = 'BOOKER_FULL_PAYMENT',
  BOOKER_ADD_ON_PAYMENT = 'BOOKER_ADD_ON_PAYMENT',
  BOOKER_FINAL_PAYMENT = 'BOOKER_FINAL_PAYMENT',
}

export enum PaymentTypeTranslation {
  BOOKER_DOWN_PAYMENT = 'Anzahlung',
  BOOKER_FULL_PAYMENT = 'Vollständige Zahlung',
  BOOKER_ADD_ON_PAYMENT = 'Kostenanpassung',
  BOOKER_FINAL_PAYMENT = 'Abschlusszahlung',
}

export type Payment = {
  uuid?: string
  projectUUID: string
  projectAddOnUUID?: string
  bookerUUID: string
  artistUUID: string
  type: PaymentType
  netInCents: number
  taxInCents: number
  taxPercentage: number
  totalInCents: number
  completed: Boolean
  currency: string
  paymentURL?: string
  paymentID?: string
  stornoInvoiceUUID?: string
  createdOn?: Date
  addOn?: AddOn
}
