import useGetArtist from '../../hooks/useGetArtist'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import { Level, PurposeFlag } from '../../../types/Auth'
import useGetBooker from '../../hooks/useGetBooker'
import Icon from './Icon'

type Props = {
  onClick?: () => void
  className?: string
}

const ProfileIcon: React.FC<Props> = ({ onClick, className }) => {
  const [artist] = useGetArtist()
  const [booker] = useGetBooker()
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [image, setImage] = useState<string>(null)

  useEffect(() => {
    if (accountLink) {
      if (accountLink.purposeFlag === PurposeFlag.ARTIST) {
        if (artist && _.has(artist, 'profileImages.personImageURL')) {
          setImage(artist.profileImages.personImageURL)
        }
      } else if (accountLink.purposeFlag === PurposeFlag.BOOKER) {
        if (booker && _.has(booker, 'profileImages.personImageURL')) {
          setImage(booker.profileImages.personImageURL)
        }
      }
    }
  }, [artist, accountLink, booker])

  return (
    <div className={`${!!onClick ? 'cursor-pointer' : ''}`} onClick={onClick ? onClick : null}>
      {accountLink.purposeFlag === PurposeFlag.ARTIST && <Icon artist={artist} image={image} className={className} />}
      {accountLink.purposeFlag === PurposeFlag.BOOKER && <Icon booker={booker} image={image} className={className} />}
      {accountLink.level === Level.ADMIN && <Icon booker={booker} image={image} className={className} />}
    </div>
  )
}

export default ProfileIcon
