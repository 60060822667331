const Prop: React.FC<{ label: string; value: string | number }> = ({ label, value }) => {
  return (
    <div className="flex gap-3 justify-between">
      <div className="text-sm font-bold">{label}</div>
      <div className="text-sm">{value}</div>
    </div>
  )
}

export default Prop
