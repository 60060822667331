import { Artist } from '../../../types/Artist'
import { Booker } from '../../../types/Booker'
import { Invoice } from '../../../types/Invoice'
import { Project } from '../../../types/Project'
import { Rating } from '../../../types/Rating'

export type User = (Artist & { projectCount: number }) | (Booker & { projectCount: number })

export type Users = {
  artists: User[]
  bookers: User[]
}

export enum TypeSelection {
  ALL = 'ALL',
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
}

export enum Type {
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
}

export type UserInsight = {
  projects?: Project[]
  invoices?: Invoice[]
  booker?: Booker
  artist?: Artist
  ratings?: Rating[]
}
