import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Milestone, MilestoneType } from '../../../../types/Milestone'
import { getPrettyDate, getPrettyDateTime } from '../../../../utility'
import doubleChecked from '../../../../assets/image/milestone/double-checked.png'
import Remove from './Remove'
import { UpdateListCallback } from '../types'
import Settings from './Settings'
import Separator from './Separator'
import Accept from './Accept'
import AddOnDecision from './AddOn/Decision'
import AddOnPrice from './AddOn/Price'
import { Project, Status } from '../../../../types/Project'
import DeclineCompletion from './DeclineCompletion'
import CompleteFinalPaymentHint from './CompleteFinalPaymentHint'

type Props = {
  project: Project
  milestone: Milestone
  isArtist: boolean
  updateListCallback?: UpdateListCallback
  minified?: boolean
  hasOpenAddOn?: boolean
  milestones: Milestone[]
  readOnly?: boolean
}

const SingleMilestone: React.FC<Props> = ({
  project,
  milestone,
  milestones,
  isArtist,
  updateListCallback,
  minified = false,
  hasOpenAddOn = false,
  readOnly = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const hasDeadline = !!milestone.deadline && !milestone.completedOn && !milestone.isCompleted

  const isAddOn = milestone.type === MilestoneType.PROJECT_ADD_ON

  return (
    <>
      <div className={`relative border border-blue-primary rounded-md ${milestone.isCompleted ? 'bg-blue-primary' : 'bg-white'}`}>
        <div className={`absolute -left-3 top-0 h-full flex items-center justify-center text-sm`}>
          <div className="h-6 aspect-square bg-white flex items-center justify-center text-blue-primary rounded-full border border-blue-primary">
            {milestone.index + 1}
          </div>
        </div>
        <div className="flex flex-row items-start md:items-center px-5 py-3">
          {!minified && <div className="w-1/6"></div>}
          <div className={minified ? 'w-full' : 'w-4/6'}>
            <div className={`text-center ${minified ? 'text-sm' : ''} ${milestone.isCompleted ? 'text-white' : 'text-blue-primary'}`}>
              {t.milestone.type[milestone.type]}
            </div>
            {isAddOn && <AddOnPrice project={project} milestone={milestone} minified={minified} isArtist={isArtist} readOnly={readOnly} />}
            {!isAddOn && (
              <CompleteFinalPaymentHint project={project} milestone={milestone} minified={minified} isArtist={isArtist} readOnly={readOnly} />
            )}
            {(hasDeadline || (milestone.isCompleted && !!milestone.completedOn)) && (
              <div
                className={`text-blue-primary rounded-full border border-blue-primary bg-white w-fit px-3 mx-auto text-center ${
                  minified ? 'text-xs' : 'text-xs md:text-sm'
                }`}
              >
                {hasDeadline
                  ? `${t.milestone.deadlinePrefix}${getPrettyDate(new Date(milestone.deadline))}`
                  : getPrettyDateTime(new Date(milestone.completedOn))}
              </div>
            )}
          </div>
          {!minified && project.status === Status.IN_PROGRESS && !readOnly && (
            <div className="w-1/6">
              {milestone.isCompleted && (
                <div className="flex justify-end">
                  <img src={doubleChecked} className="h-6" alt="" />
                </div>
              )}
              {!milestone.isCompleted && (
                <div className="flex items-center justify-end gap-3">
                  {!minified && !isAddOn && (
                    <>
                      <div className="w-6 flex items-center justify-center shrink-0">
                        {!milestone.isCompleted && !!updateListCallback && (
                          <Accept
                            milestone={milestone}
                            milestones={milestones}
                            isArtist={isArtist}
                            updateListCallback={updateListCallback}
                            hasOpenAddOn={hasOpenAddOn}
                            project={project}
                          />
                        )}
                      </div>
                      {!milestone.isCompleted && !!updateListCallback && (
                        <DeclineCompletion
                          milestone={milestone}
                          milestones={milestones}
                          isArtist={isArtist}
                          updateListCallback={updateListCallback}
                        />
                      )}
                    </>
                  )}
                  {isAddOn && !isArtist && !milestone.isCompleted && !!updateListCallback && (
                    <AddOnDecision milestone={milestone} updateListCallback={updateListCallback} />
                  )}
                  {!!updateListCallback &&
                    isArtist &&
                    !isAddOn &&
                    milestone.type !== MilestoneType.PROJECT_COMPLETED &&
                    milestone.type !== MilestoneType.COMPLETE_FINAL_PAYMENT && (
                      <div className="w-6 flex justify-center items-center shrink-0">
                        <Settings project={project} milestone={milestone} updateListCallback={updateListCallback} isArtist={isArtist} />
                      </div>
                    )}
                  {!!updateListCallback && isArtist && <Remove milestone={milestone} updateListCallback={updateListCallback} />}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {milestone.type !== MilestoneType.PROJECT_COMPLETED && <Separator minified={minified} />}
    </>
  )
}

export default SingleMilestone
